import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import logo_otchee from "../assets/images/otchee-logo.png";

export default function NavBar(): JSX.Element {
  const { t } = useTranslation();
  return (
    <header id="header" className="fixed-top header-scrolled">
      <Navbar expand="md" collapseOnSelect>
        <Container className="d-flex align-items-center">
          <a href="" className="logo">
            <img src={logo_otchee} alt="logo" className="img-fluid" />
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-lg-end justify-content-md-center">
            <Nav.Link href="#home">{t("navbar.home")}</Nav.Link>
            <Nav.Link href="#about-us">{t("navbar.aboutus")}</Nav.Link>
            <Nav.Link href="#services">{t("navbar.services")}</Nav.Link>
            <Nav.Link href="#contact-us">{t("navbar.contact")}</Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
