import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function AboutUs(): JSX.Element {
  const { t } = useTranslation();

  return (
    <section id="about-us" className="about">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>{t("aboutus.title")}</h2>
        </div>

        <Row className="content" style={{ textAlign: "justify" }}>
          <Col lg="6" className="px-5">
            <p>{t("aboutus.p1")}</p>
            <p>{t("aboutus.p2")}</p>
          </Col>
          <Col lg="6" className="px-5">
            <p>{t("aboutus.p3")}</p>
            <a href="#about-us" className="btn-learn-more">
              {t("aboutus.info")}
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
