import React, { Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./components/Navbar";
import Welcome from "./components/Welcome";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import ContactForm from "./components/ContactForm";

function App(): JSX.Element {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);
  return (
    <Suspense fallback={<div id="preloader" />}>
      <Navbar />
      <Welcome />
      <Partners />
      <AboutUs />
      <Services />
      <ContactForm />
      <Footer />
    </Suspense>
  );
}

export default App;
