import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import heroImage from "../assets/images/components/welcome/hero-img.png";
import { Trans, useTranslation } from "react-i18next";

export default function Welcome(): JSX.Element {
  const { t } = useTranslation();
  return (
    <section id="home" className="d-flex align-items-center">
      <Container>
        <Row>
          <Col
            lg="6"
            className="d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <h1>
              <Trans i18nKey="welcome.h1">
                A <span style={{ color: "orange" }}>custom made</span>
                <br />
                IT & digital expertise
              </Trans>
            </h1>
            <br />
            <h2>{t("welcome.h2")}</h2>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="#about-us" className="btn-get-started">
                {t("welcome.info")}
              </a>
            </div>
          </Col>
          <Col
            lg="6"
            className="order-1 order-lg-2 hero-img"
            data-aos="zoom-in"
            data-aos-delay={200}
          >
            <Image src={heroImage} fluid className="animated" alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
